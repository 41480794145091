import { useState, useEffect, useCallback } from "react";
import { axiosAuth as axios } from "./axios-instance";
import { useAuth } from "./auth-hook";

export const useData = () => {
  const [rulebookDate, setRuleBookDate] = useState("");
  const [rulebookRef, setRuleBookRef] = useState("");
  const [dateRequest, setDateRequest] = useState("");
  const [year, setYear] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [job, setJob] = useState("");
  const [duration, setDuration] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [returnDate, setReturnDate] = useState("");
  const [gender, setGender] = useState("");
  const [allow, setAllow] = useState(false);
  const { logout } = useAuth();

  const fetchRulebookInfo = useCallback(async () => {
    try {
      const { data } = await axios.get("/api/admin?type=rulebook");

      data.data.forEach((doc) => {
        doc.name.includes("/")
          ? setRuleBookRef(doc.name)
          : setRuleBookDate(doc.name);
      });
    } catch (err) {
      if (err.response.status === 401) {
        logout();
      }
    }
    // eslint-disable-next-line
  }, []);

  const clearState = () => {
    setAllow(false);
    setDateRequest("");
    setYear("");
    setFirstName("");
    setLastName("");
    setDuration("");
    setJob("");
    setStartDate("");
    setEndDate("");
    setReturnDate("");
    setGender("");
  };

  const setter = (e, stateFunc) => {
    stateFunc(e.target.value);
  };

  const setters = {
    dateRequestSetter: (e) => setter(e, setDateRequest),
    yearSetter: (e) => setter(e, setYear),
    firstNameSetter: (e) => setter(e, setFirstName),
    lastNameSetter: (e) => setter(e, setLastName),
    durationSetter: (e) => setter(e, setDuration),
    jobSetter: (e) => setter(e, setJob),
    startDateSetter: (e) => setter(e, setStartDate),
    endDateSetter: (e) => setter(e, setEndDate),
    returnDateSetter: (e) => setter(e, setReturnDate),
    genderSetter: (e) => setter(e, setGender),
  };

  useEffect(() => {
    if (
      ![
        rulebookDate,
        rulebookRef,
        dateRequest,
        year,
        firstName,
        lastName,
        job,
        duration,
        startDate,
        returnDate,
        gender,
      ].includes("")
    )
      setAllow(true);
    else setAllow(false);
  }, [
    rulebookDate,
    rulebookRef,
    dateRequest,
    year,
    firstName,
    lastName,
    job,
    duration,
    startDate,
    returnDate,
    gender,
  ]);

  return {
    allow,
    rulebookDate,
    rulebookRef,
    dateRequest,
    year,
    firstName,
    lastName,
    job,
    duration,
    startDate,
    endDate,
    returnDate,
    gender,
    setters,
    clearState,
    fetchRulebookInfo,
  };
};
