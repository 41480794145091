/* eslint-disable */
import { useState, useCallback, useEffect } from "react";
import { axiosNonAuth as axios } from "./axios-instance";
import { useNavigate } from "react-router-dom";

export const useAuth = () => {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  // const [expiration, setExpiration] = useState();
  // const [timer, setTimer] = useState();
  const [response, setResponse] = useState({
    status: 0,
    message: "",
  });

  const addResponse = (status, message) => {
    setResponse({
      status: status,
      message: message,
    });
  };

  const clearResponse = () => {
    setResponse({
      status: 0,
      message: "",
    });
  };

  const login = useCallback(async (data) => {
    try {
      const res = await axios.post("/api/user/login", data);

      localStorage.setItem("userData", JSON.stringify(res.data));
      // const { exp } = JSON.parse(atob(res.data.token.split(".")[1]));

      if (res.status !== 0) {
        addResponse(res.status, "Success");
        setTimeout(() => {
          // setExpiration(exp);
          setUser({ id: res.data.id, active: res.data.active });
          clearResponse();
          const url = res.data.active ? "/dashboard" : "/inactive";
          navigate(url);
        }, 2000);
      }
    } catch (error) {
      addResponse(error.response.status, error.response.data.message);
      setTimeout(() => {
        clearResponse();
      }, 2000);
      setUser(null);
      // setExpiration(null);
      localStorage.clear();
    }
  }, []);

  const registerAccount = useCallback(async (data) => {
    if (data.password !== data.repeatPassword) {
      addResponse(500, "Please enter the same password twice");
      setTimeout(() => {
        clearResponse();
      }, 2000);
    } else
      try {
        const res = await axios.post("/api/user/register", data);
        addResponse(res.status, res.data.message);
        setTimeout(() => {
          clearResponse();
        }, 2000);
      } catch (error) {
        addResponse(error.response.status, error.response.data.message);
        setTimeout(() => {
          clearResponse();
        }, 2000);
      }
  }, []);

  const logout = useCallback(() => {
    setUser(null);
    // setExpiration(null);
    // setTimer(null);
    localStorage.clear();
    navigate("/");
  }, []);

  const forgotPassword = useCallback(async (data) => {
    try {
      const res = await axios.patch("/api/user/forgot-password", data);
      addResponse(res.status, res.data.message);
      setTimeout(() => {
        clearResponse();
      }, 2000);
    } catch (error) {
      addResponse(error.response.status, error.response.data.message);
      setTimeout(() => {
        clearResponse();
      }, 2000);
    }
  }, []);

  const changePassword = useCallback(async (data, id) => {
    try {
      const res = await axios.patch(`/api/user/${id}`, data);
      addResponse(res.status, res.data.message);
      setTimeout(() => {
        clearResponse();
      }, 2000);
    } catch (error) {
      addResponse(error.response.status, error.response.data.message);
      setTimeout(() => {
        clearResponse();
      }, 2000);
    }
  }, []);

  useEffect(() => {
    const loggedIn = localStorage.getItem("userData");
    if (loggedIn) {
      setUser(JSON.parse(loggedIn));
    }
  }, []);

  return {
    user,
    login,
    logout,
    registerAccount,
    forgotPassword,
    changePassword,
    response,
    addResponse,
    clearResponse,
  };
};
